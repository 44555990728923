import UIkit from 'uikit';

import jQuery from "jquery";
import Plyr from 'plyr';
import "./project.scss";

const $ = jQuery;

// HTMX and hyperscript
// import htmx from "htmx.org";
// import hyperscript from "hyperscript.org";
// window.htmx = require('htmx.org');

// import hyperscript from 'hyperscript.org';
// hyperscript.browserInit();

// import styles

const QRCode = require('qrcode');

jQuery(document).ready(function ($) {
    $('[data-qrcode]').each(function(i,el) {
        const url = $(el).attr('data-qrcode');
        const options = {
            scale: 8
        }
        QRCode.toCanvas(el, url, options, function (error) {
            if (error) console.error(error);
            $(el).css("width", "")
            $(el).css("height", "");
        })
    });
    
    $('.plyr-videoplayer').each(function() {
        console.log("+--------------------creating plyr for", this);
        const container = $('<div></div>').addClass('plyr-container');
        $(container).insertBefore(this);
        $(this).appendTo(container);
        
        const plyr =  new Plyr(this);
        
        console.log('--------------- adding plyr to container',plyr,container);
        
        $(container).data('plyr', plyr)
    });
});

export {UIkit,Plyr,$};